import * as React from "react";
import styled from '@emotion/styled';
import { Link as GatsbyLink } from "gatsby";

export const About = styled.div`
    min-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
`;

export const Company = styled.div`
    color: white;
    max-width: 45rem;
    padding: 1rem;

    &>h1 {
        text-align: center;
    }
`;

export const Team = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: black;
`;

export const Cards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    gap: 4rem 6rem;
    max-width: 60rem;
    padding: 1.5rem 0rem;
`;

const CardContainer = styled.div`
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: Poppins-Light;
`;

const Name = styled.p`
    font-size: 1.5rem;
    padding: 1rem 0;
    text-align: center;
`;

const Title = styled.p`
    white-space: break-spaces;
    padding-bottom: 1rem;
    text-align: center;
`;

const Picture = styled.img`
    display: block;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
`;

const Socials = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;
export const SocialLink = styled(GatsbyLink)`
    display: block;
    text-decoration: none;
    color: white;
    align-items: baseline;
    justify-content: center;

    &.active {
        font-weight: bold;
    }
`;

const SocialIcon = styled.img`
    display: block;
    width: 32px;
    height: 32px;

`;

export interface SocialReference {
    url: string,
    icon: ImageReference,
}

export interface ImageReference {
    url: string,
}

export interface CardProps {
    picture: ImageReference,
    name: string,
    title: string,
    socials: SocialReference[],
};

export const Card = (props: CardProps) => {
    const socials = props.socials.map((s, i) => (
        <SocialLink to={s.url} target="_blank" key={i}>
            <SocialIcon src={s.icon.url} />
        </SocialLink>
    ));
    return (<CardContainer>
        <Picture src={props.picture.url} />
        <Name>{props.name}</Name>
        <Title>{props.title}</Title>
        <Socials>
            {socials}
        </Socials>
    </CardContainer>)
};
